<template>
  <div>
    <b-sidebar
      id="sidebar-withdrawal-request"
      sidebar-class="sidebar-lg"
      :visible="saveExpenseSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateExpenseSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4 class="mb-0">Nova despesa</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <label for="name">Nome</label>
              <b-form-input
                v-model="name"
                type="text"
                :class="{ 'is-invalid': v$.name.$error }"
              />
              <div class="invalid-feedback">
                <span v-if="v$.name.required.$invalid">
                  Você deve informar o nome da despesa
                </span>
              </div>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Valor" label-for="amount">
                <b-form-input
                  id="amount"
                  v-money="moneyFormat"
                  v-model="amount"
                  :class="{ 'is-invalid': v$.amount.$error }"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.amount.required.$invalid || v$.amount.maxValue.$invalid
                    "
                  >
                    Você deve informar um valor válido
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Aplicação do valor" label-for="expense-credit-type">
                <v-select
                  id="expense-credit-type"
                  v-model="creditType"
                  :reduce="(option) => option.value"
                  :options="creditTypesOptions"
                  label="label"
                  :class="getSelectErrorClass(v$.creditType.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.creditType.required.$invalid">
                    Você deve informar a forma de aplicação do lançamento
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Data" label-for="launch-date">
                <flat-pickr
                  id="launch-date"
                  v-model="launchDate"
                  class="form-control"
                  :config="flatPickrConfig"
                  :class="{ 'is-invalid': v$.launchDate.$error }"
                />

                <div class="invalid-feedback">
                  <span v-if="v$.launchDate.required.$invalid">
                    Você deve informar a data
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Empresa" label-for="group_company">
                <v-select
                  id="group_company"
                  v-model="groupCompanie"
                  :reduce="(groupCompanie) => groupCompanie.id"
                  :options="groupCompanies"
                  :loading="loading.groupCompanies"
                  label="name"
                  :class="getSelectErrorClass(v$.groupCompanie.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.groupCompanie.required.$invalid">
                    Você deve informar uma empresa
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Centro de custo" label-for="cost-center">
                <v-select
                  id="cost-center"
                  v-model="costCenter"
                  :reduce="(cost_center) => cost_center.id"
                  :options="costCenters"
                  :loading="loading.costCenters"
                  @input="costCenterChanged"
                  label="name"
                  :class="getSelectErrorClass(v$.costCenter.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.costCenter.required.$invalid">
                    Você deve informar o centro de custo
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2" v-if="costCenter">
              <b-form-group
                label="Categoria do centro de custo"
                label-for="category"
              >
                <v-select
                  id="category"
                  v-model="costCenterCategory"
                  :reduce="(category) => category.id"
                  :options="costCenterCategories"
                  :loading="loading.costCenterCategories"
                  label="name"
                  :class="getSelectErrorClass(v$.costCenterCategory.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.costCenterCategory.required.$invalid">
                    Você deve informar a categoria do centro de custo
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2" v-if="costCenter">
              <b-form-group
                label="Colaborador"
                label-for="employee"
              >
                <v-select
                  id="employee"
                  v-model="employee"
                  :reduce="(item) => item.id"
                  :options="employees"
                  :loading="loading.employees"
                  label="name"
                  @input="employeeChanged"
                  :class="getSelectErrorClass(v$.employee.$error)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.employee.required.$invalid">
                    Você deve informar um colaborador para realizar o desconto
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <template v-if="!isDebit">            
              <b-col cols="12" class="mt-2">
                <b-form-group label="Tipo do pagamento" label-for="payment-type">
                  <v-select
                    id="payment-type"
                    v-model="paymentType"
                    :reduce="(payment) => payment.id"
                    :options="paymentTypes"
                    :loading="loading.paymentTypes"
                    label="name"
                    :class="getSelectErrorClass(v$.paymentType.$error)"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.paymentType.required.$invalid">
                      Você deve informar o tipo de pagamento
                    </span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-2">
                <label for="name">Chave do pagamento</label>
                <b-form-textarea
                  v-model="paymentKey"
                  :class="{ 'is-invalid': v$.paymentKey.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.paymentKey.required.$invalid">
                    Você deve informar uma chave de pagamento
                  </span>
                </div>
              </b-col>
            </template>
            <b-col v-if="!isEdit" cols="12" class="mt-2">
              <b-form-checkbox
                :checked="hasRecurrence"
                v-model="hasRecurrence"
                switch
                inline
              >
                Haverá recorrência desta despesa
              </b-form-checkbox>
            </b-col>
            <b-col v-if="!isEdit && hasRecurrence" cols="12" class="mt-2">
              <label for="recurrenceInMonths">Recorrência (em meses)</label>
              <b-form-input
                v-model="recurrenceInMonths"
                type="number"
                :class="{ 'is-invalid': v$.recurrenceInMonths.$error }"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.recurrenceInMonths.required.$invalid ||
                    v$.recurrenceInMonths.between.$invalid
                  "
                >
                  A recorrência suporta valores de 1 à 12 meses
                </span>
              </div>
            </b-col>
            <b-col cols="12" class="mt-2">
              <label for="comment">Comentário</label>
              <b-form-textarea
                id="comment"
                v-model="comment"
                class="mb-2 mb-lg-0"
              />
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
  BFormInput,
} from "bootstrap-vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, between, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import MoneyInput from "@/modules/shared/components/MoneyInput";
import { VMoney } from "v-money";
import { getVueSelectErrorClass } from "@/helpers/validators";
import { getBRLFormat } from "@/helpers/formatting";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { PIX } from "@/constants/payment_types";
import { creditTypesOptions, CREDIT } from "@/constants/credit_types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BCol,
    BRow,
    BFormInput,
    vSelect,
    DynamicSelect,
    MoneyInput,
    flatPickr,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      loading: {
        types: false,
        paymentTypes: false,
        groupCompanies: false,
        costCenters: false,
        costCenterCategories: false,
        paymentKeys: false,
        employees: false
      },
      creditTypesOptions,
      saving: false,
      name: undefined,
      amount: undefined,
      launchDate: undefined,
      paymentType: undefined,
      groupCompanie: undefined,
      hasRecurrence: false,
      recurrenceInMonths: "1",
      comment: undefined,
      costCenter: undefined,
      costCenterCategory: undefined,
      paymentKey: undefined,
      employee: undefined,
      creditType: CREDIT,
      flatPickrConfig: {
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "Y-m-d",
        enableTime: false,
        locale: Portuguese,
      },
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      name: { required },
      amount: {
        required,
        maxValue: function (value) {
          let amount = moneyToFloat(value);
          return amount > 0;
        },
      },
      creditType: { required },
      launchDate: { required },
      paymentType: { required: requiredIf(() => {
        return !this.isDebit;
      }) },
      groupCompanie: { required },
      paymentKey: { required: requiredIf(() => {
        return !this.isDebit;
      }) },
      hasRecurrence: {},
      recurrenceInMonths: {
        required,
        between: between(1, 12),
      },
      comment: {},
      costCenter: { required },
      costCenterCategory: {
        required: requiredIf(() => {
          return this.costCenter;
        }),
      },
      employee: {
        required: requiredIf(() => {
          return this.isDebit;
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      saveExpenseSidebar: types.SAVE_EXPENSE_SIDEBAR,
      costCenters: sharedTypes.COST_CENTERS,
      costCenterCategories: sharedTypes.COST_CENTER_CATEGORIES,
      groupCompanies: sharedTypes.GROUP_COMPANIES,
      paymentTypes: sharedTypes.PAYMENT_TYPES,
      employees: sharedTypes.EMPLOYEES_BY_COST_CENTER
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
    isEdit: function () {
      return this.saveExpenseSidebar?.id;
    },
    isDebit: function () {
      return this.creditType != CREDIT;
    },
  },
  mounted() {
    this.loading.paymentTypes = true;
    this.getPaymentTypes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os tipos de pagamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.paymentTypes = false;
      });
    this.loading.reasons = true;
    this.getGroupCompanies()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as empresas. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.reasons = false;
      });
    this.loading.costCenters = true;
    this.getCostCenters()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os centros de custo. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.costCenters = false;
      });
  },
  methods: {
    floatToMoney,
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    ...mapMutations({
      mutateExpenseSidebar: types.MUTATE_EXPENSE_SIDEBAR,
    }),
    ...mapActions({
      saveExpense: types.SAVE_EXPENSE,
      getCostCenters: sharedTypes.GET_COST_CENTERS,
      getCostCenterCategories: sharedTypes.GET_COST_CENTER_CATEGORIES,
      getGroupCompanies: sharedTypes.GET_GROUP_COMPANIES,
      getPaymentTypes: sharedTypes.GET_PAYMENT_TYPES,
      getEmployees: sharedTypes.GET_EMPLOYEES_BY_COST_CENTER,
      getExpense: types.GET_EXPENSE
    }),
    onShow() {
      if (this.saveExpenseSidebar?.id) {
        this.getExpense(this.saveExpenseSidebar?.id)
          .then((response) => {
            if(response.data){
              this.name = response.data.name;
              this.amount = floatToMoney(response.data.amount);
              this.launchDate = response.data.launch_date;
              this.paymentType = response.data.payment_type_id;
              this.groupCompanie = response.data.group_company_id;
              this.comment = response.data.description;
              this.costCenter = response.data.cost_center_id;
              this.costCenterChanged()
              this.costCenterCategory = response.data.cost_center_category_id;
              this.paymentKey = response.data.payment_key.toString();
              this.employee = response.data.consultant_id;
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar a despesa. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const amount = moneyToFloat(this.amount);
      const payload = {
        name: this.name,
        amount: this.isDebit ? amount* -1:amount,
        launch_date: this.launchDate,
        payment_type_id: this.paymentType,
        recurrence_in_months: 1,
        group_company_id: this.groupCompanie,
        description: this.comment,
        cost_center_id: this.costCenter,
        cost_center_category_id: this.costCenterCategory,
        payment_key: this.paymentKey,
        employee: this.employee
      };
      const recurrenceInMonths = parseInt(this.recurrenceInMonths);
      if (!isNaN(recurrenceInMonths)) {
        payload.recurrence_in_months = recurrenceInMonths;
      }
      if (this.saveExpenseSidebar?.id) {
        payload.id = this.saveExpenseSidebar?.id;
      }
      this.saveExpense(payload)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Despesa salva com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.saveExpenseSidebar.saveAction();
          this.mutateExpenseSidebar({ visible: false });
        })
        .catch((err) => {
          let message =
            "Ocorreu um erro ao salvar a despesa. Entre em contato com o setor de TI.";
          if (err.response.status == 405) {
            message = err.response.data.message;
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    costCenterChanged() {
      this.costCenterCategory = undefined;
      this.fetchCostCenterCategories();
    },
    fetchCostCenterCategories() {
      if (!this.costCenter) return;
      this.loading.costCenterCategories = true;
      this.getCostCenterCategories(this.costCenter)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as categorias de centro de custo. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.costCenterCategories = false;
        });
      this.loading.employees = true 
      this.getEmployees(this.costCenter)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os colaboradores. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.employees = false;
        });
    },
    employeeChanged() {
      if(!this.employee || this.isDebit) {
        this.paymentType = undefined
        this.paymentKey = undefined
      }
      const selected = _.find(this.employees, {id: this.employee })
      if(selected && !this.isDebit) {
        this.paymentType = PIX
        this.paymentKey = 'Chave Pix: ' + selected.pix_key + 
          '; Tipo da chave: ' + selected.pix_type
      }
    },
    clear() {
      this.name = undefined;
      this.amount = undefined;
      this.launchDate = undefined;
      this.hasRecurrence = false;
      this.recurrenceInMonths = 1;
      this.comment = undefined;
      this.costCenter = undefined;
      this.costCenterCategory = undefined;
      this.paymentType = undefined;
      this.groupCompanie = undefined;
      this.paymentKey = undefined;
      this.employee = undefined;
      this.mutateExpenseSidebar({ id: undefined });
      this.v$.$reset();
    },
  },
};
</script>
